import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Carousel, { Modal, ModalGateway } from 'react-images'
import FileDrop from '../../../../components/FileDrop'
import ChurchInfo from '../ChurchInfo'
import { useInputChange } from '../../../../hooks'
import { useTranslation } from 'react-i18next'

import { toast } from 'react-toastify'

import './UserFeed.scss'


const Post = ({ post, delay, church, currentUser, toggleEditModal, api, onDelete }) => {
    const { title, body, user, images } = post
    const [modalIsOpen, setModalOpen] = useState(false);
    const [optionsIsOpen, setOptionsOpen] = useState(false);
    const { t } = useTranslation()

    const isAuthor = post.userId === currentUser.user_id
    const canDeletePost = church.churchAdmins.includes(currentUser.id) || currentUser.roles.includes('developer') || isAuthor
    const canEditPost = isAuthor

    async function deletePost(){
        try {
            const confirmed = window.confirm('Are you sure you want to delete this post?')
            if (confirmed){
                const result = await api.deletePost(post.id)
                if (result.error){
                    console.error(result.error)
                    toast.error(JSON.stringify(result.error))
                }
                else {
                    onDelete()
                }
            }
        }
        catch(err){
            console.error(err)
            toast.error(err)
        }
    }
    
    return (
        <div class="card fadeInBottom" style={{ animationDelay: `${delay}s` }}>
            <div class="card-border"></div>
            <div class="card-header">
                <h1 class="card-title">
                    {title}

                    <div onClick={() => setOptionsOpen(!optionsIsOpen)} class="navbar-item has-dropdown card-header-icon">
                        <a class="card-header-icon" aria-label="more options" >
                            <span class="icon">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </a>

                        { optionsIsOpen && 
                            <div class="navbar-dropdown post-options">
                                { canEditPost && 
                                    <a class="navbar-item" onClick={() => toggleEditModal(post)}>
                                        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                                        {t('posts.edit')}
                                    </a>
                                }
                                { ( canEditPost && canDeletePost ) && <hr class="navbar-divider" /> }
                                {
                                    canDeletePost && 
                                    <a class="navbar-item has-text-danger" onClick={deletePost}>
                                        <i class="fas fa-trash-alt" aria-hidden="true"></i>
                                        {t('posts.delete')}
                                    </a>
                                }
                            </div>
                        }
                    </div>
                </h1>
                <div class="card-subtitle">
                    <Link to={`/user/${post.userId}`}>{`${user.firstName} ${user.lastName}`}</Link>
                </div>
            </div>

            <div class="card-content">
                <p>{body}</p>
                
                { images && images.length > 0 && (
                    <>
                        <div class="card-images">
                            { images.map( imageSrc => <img onClick={() => setModalOpen(!modalIsOpen)} src={imageSrc} /> ) }
                        </div>
                        <ModalGateway>
                            {modalIsOpen && (
                                <Modal onClose={() => setModalOpen(!modalIsOpen) }>
                                    <Carousel views={images.map( imageSrc => ({ source: imageSrc }))} />
                                </Modal>
                            )}
                        </ModalGateway>
                    </>
                )}
            </div>
        </div>
    )
}

const NewPostModal = ({isHidden, toggleModal, onSubmit, api, churchId}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [ input, handleInputChange ] = useInputChange()
    const [ images, setImages ] = useState([])
    const { t } = useTranslation()

    async function handleSubmit(){
        setIsLoading(true)
        try {
            const post = {
                churchId,
                images,
                ...input
            }
            const result = await api.createPost(post)
            if (result.error){
                console.error(result.error)
                setError(JSON.stringify(result.error))
            }
            else {
                onSubmit()
                toggleModal(!isHidden)
            }
        }
        catch(err){
            console.error(err)
            setError(JSON.stringify(err))
        }
        setIsLoading(false)
    }

    return (
        <div class={`modal ${isHidden && 'is-active'}`}>
            <div class="modal-background" onClick={() => toggleModal(!isHidden)}></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title"><i class="fas fa-pencil-alt" style={{ marginRight: '5px' }}/> {t('posts.new')}</p>
                    <button class="delete" aria-label="close" onClick={() => toggleModal(!isHidden)}></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">{t('posts.modal.title')}</label>
                        <div class="control">
                            <input class="input" type="text" name="title" onChange={handleInputChange} />
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">{t('posts.modal.message')}</label>
                        <div class="control">
                            <textarea class="textarea" name="body" placeholder="I'm looking to..." onChange={handleInputChange}></textarea>
                        </div>
                    </div>

                    <FileDrop handleDrop={setImages} files={images} />
                </section>
                <footer class="modal-card-foot">
                    <button class={`button is-success ${isLoading && 'is-loading'}`} onClick={handleSubmit}>{t('posts.modal.save')}</button>
                    <button class={`button ${isLoading && 'is-loading'}`} onClick={() => toggleModal(!isHidden)}>{t('posts.modal.cancel')}</button>
                    { error && <p class="has-text-danger">{error}</p>}
                </footer>
            </div>
        </div>
    )
}

const EditPostModal = ({isHidden, toggleModal, post, onSubmit, api, churchId}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [ input, handleInputChange ] = useInputChange()
    const [ files, setFiles ] = useState([])
    const [ images, setImages ] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        setImages(post.images || [])
    }, [post.images])

    async function handleSubmit(){
        setIsLoading(true)
        try {
            const updatedImages = images.concat(files)
            if (updatedImages.length > 4){
                throw new Error('Image limit exceeded, please remove an image')
            }
            console.log(updatedImages)
            const updateFields = {
                images: updatedImages,
                ...input
            }
            const result = await api.updatePost(post.id, updateFields)
            if (result.error){
                console.error(result.error)
                setError(JSON.stringify(result.error))
            }
            else {
                toggleModal(!isHidden)
                setError('')
                setFiles([])
                setImages([])
                onSubmit()
            }
        }
        catch(err){
            console.error(err.message)
            setError(err.message)
        }
        setIsLoading(false)
    }

    async function removeImage(imgSrc){
        const updatedImages = images.filter( img => img !== imgSrc)
        setImages(updatedImages)
    }

    return (
        <div class={`modal ${isHidden && 'is-active'}`}>
            <div class="modal-background" onClick={() => toggleModal(!isHidden)}></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title"><i class="fas fa-pencil-alt" style={{ marginRight: '5px' }}/>Edit Post</p>
                    <button class="delete" aria-label="close" onClick={() => toggleModal(!isHidden)}></button>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">{t('posts.modal.title')}</label>
                        <div class="control">
                            <input class="input" type="text" name="title" defaultValue={post.title} onChange={handleInputChange} />
                        </div>
                    </div>

                    <div class="field">
                        <label class="label">{t('posts.modal.message')}</label>
                        <div class="control">
                            <textarea class="textarea" name="body"  defaultValue={post.body} onChange={handleInputChange}></textarea>
                        </div>
                    </div>

                    {
                        (images && images.length > 0) &&
                        <div class="card-images">
                            { 
                                images.map( imageSrc => (
                                    <div>
                                        <span onClick={() => removeImage(imageSrc)} class="remove-image-badge"><i class="fas fa-times-circle"/></span>
                                        <img src={imageSrc} /> 
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <FileDrop handleDrop={setFiles} existingImages={images ? images.length : 0} files={files} />
                </section>
                <footer class="modal-card-foot">
                    <button class={`button is-success ${isLoading && 'is-loading'}`} onClick={handleSubmit}>{t('posts.modal.save')}</button>
                    <button class={`button ${isLoading && 'is-loading'}`} onClick={() => toggleModal(!isHidden)}>{t('posts.modal.cancel')}</button>
                    { error && <p class="has-text-danger">{error}</p>}
                </footer>
            </div>
        </div>
    )
}



const UserFeed = ({ api, church, currentUser }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, toggleCreateModal] = useState(false)
    const [showEditModal, toggleEditModal] = useState(false)
    const [currentPost, setCurrentPost] = useState({})
    const [posts, setPosts] = useState([])
    const { t } = useTranslation()

    const churchId = Number(church.id)

    useEffect(() => {
        async function getPosts(){
            const posts = await api.getChurchPosts(churchId)
            setPosts(posts)
            setIsLoading(false)
        }

        getPosts()
    }, [church, currentUser])

    async function refreshPosts(newPost) {
        setIsLoading(true)
        setPosts([])
        const posts = await api.getChurchPosts(churchId)
        setPosts(posts)
        setCurrentPost({})
        setIsLoading(false)
    }

    async function setEditModalData(post){
        setCurrentPost(post)
        toggleEditModal(true)
    }

    let animDelay = 0.2
    return (
        <div class="columns">
            <div class="column">
                <ChurchInfo church={church} />
            </div>
            <div class="column is-one-thirds">
                <div class="home-content">
                    <NewPostModal isHidden={showCreateModal} toggleModal={toggleCreateModal} onSubmit={refreshPosts} api={api} churchId={churchId} />
                    <EditPostModal isHidden={showEditModal} toggleModal={toggleEditModal} post={currentPost} onSubmit={refreshPosts} api={api} churchId={churchId} />
                    <div class="columns is-3 is-variable">
                        <div class="column is-10">
                            <button class="button fadeInBottom" onClick={() => toggleCreateModal(!showCreateModal)} style={{ width: '100%' }}>
                                <i class="fas fa-plus-circle fa-sm" style={{ marginRight: '5px', marginTop: '3px' }}/>
                                {t('posts.new')}
                            </button>
                        </div>
                        <div class="column">
                            <button class="button is-fullwidth fadeInBottom" onClick={refreshPosts}><i class="fas fa-sync-alt"/></button>
                        </div>
                    </div>
                    <div class="posts">
                        { 
                            posts.map( p => {
                                animDelay += 0.3; 
                                return <Post post={p} delay={animDelay} church={church} api={api} currentUser={currentUser} toggleEditModal={setEditModalData} onDelete={refreshPosts} /> 
                            })
                        }
                    </div>
                </div>
            </div>
            <div class="column">
            </div>
        </div>
    )
}

export default UserFeed