import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/browser';

import Loader from '../../components/Loader'
import Navbar from '../../components/Navbar'
import ChurchBanner from '../../components/ChurchBanner'
import ChurchCalendar from '../../components/ChurchCalendar'
import UserDirectory from '../../components/UserDirectory'
import ChurchNav from './components/ChurchNav'
import ChurchAdmin from './components/ChurchAdmin'
import UserFeed from './components/UserFeed'

import { useTranslation } from 'react-i18next'

import './ChurchHome.scss'

const ChurchHome = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = useState({})
    const { t } = useTranslation()

    useEffect(() => {
        async function getProfile(){
            setIsLoading(true)
            const user = await props.api.me()
            
            Sentry.configureScope(function(scope) {
                scope.setUser({ email: user.profile.email, id: user.user_id, user });
            });

            if (user.churches.length === 0){
                setState({ ...state, user, noChurches: true })
            }
            else {
                let currentChurch = user.churches[0]
            
                const churchId = props.match.params ? props.match.params.churchId : null;
                if (churchId == 1){
                    props.history.push(`/church/${currentChurch.id}/feed`)
                    return
                }
              
                if (churchId){
                    try {
                        currentChurch = await props.api.getChurch(churchId)
                        if (currentChurch.error) throw new Error(currentChurch.error)
                    }
                    catch(err){
                        console.error(err)       
                    }
                }
                const canEditChurch = currentChurch.churchAdmins.includes(user.user_id) || user.roles.includes('developer')
                setState({ ...state, user, currentChurch, canEditChurch })
            }

            setIsLoading(false)
        }

        getProfile() //.catch( err => { if (err === 401) props.history.push('/login') });
    }, [props.match.params.churchId])


    if (!localStorage.getItem('jwt')){
        props.history.push('/login')
    }

    return (
        <div id="home">
            <Navbar  api={props.api} user={state.user} />
            {
                !isLoading && !state.noChurches ?       
                <>
                    <ChurchBanner church={state.currentChurch} canEditChurch={state.canEditChurch} />
                        <ChurchNav church={state.currentChurch} />
            
                        <Switch>
                            <Route exact path="/church/:churchId/feed" component={() => <UserFeed api={props.api} church={state.currentChurch} currentUser={state.user} />} />
                            <Route exact path="/church/:churchId/calendar" component={() => <ChurchCalendar api={props.api} user={state.user} church={state.currentChurch} />}/>
                            <Route exact path="/church/:churchId/directory" component={() => <UserDirectory api={props.api} church={state.currentChurch} isAdmin={state.canEditChurch} />} />
                            <Route exact path="/church/:churchId/admin" component={() => <ChurchAdmin api={props.api} church={state.currentChurch} />} />
                        </Switch>
                    </> 
                : <Loader />
            }
            {
                state.noChurches && 
                    <div class="modal fadeInDown is-active" style={{ zIndex: 1 }}>
                        <div class="modal-card">
                            <header class="modal-card-head">
                                <p class="modal-card-title">{t('home.no_churches.title')}</p>
                            </header>
                            <section class="modal-card-body">
                                <p>{t('home.no_churches.description')}</p>
                            </section>
                        </div>
                    </div>
            }

        </div>
    )
}

export default ChurchHome