import React from 'react'
import { Link } from 'react-router-dom'

import './ChurchBanner.scss'

const ChurchBanner = ({ church, canEditChurch }) => {
    let imgURL = church.banner
    const churchName = church ? church.name : ''

    const bannerStyle = {  
        backgroundImage: `linear-gradient(rgba(39, 171, 131, 0.50), rgba(39, 171, 131, 0)), url("${imgURL}")`, 
        animationDuration: '3s' 
    }
    return (
        <div class="church-banner fadeIn" style={bannerStyle}>
            <p class="is-size-1 is-size-2-mobile has-text-white fadeInDown" style={{ animationDuration: '3s'}}>
                { churchName }
                { canEditChurch && <Link class="button" to={`/church/${church.id}/admin`}><i class="fas fa-pencil-alt" style={{ marginRight: '5px' }}/></Link> }
            </p>

        </div>
    )
}

export default ChurchBanner