import React, { useState } from 'react'
import { useInputChange } from '../../../hooks/index.js'
import { useTranslation } from 'react-i18next';


const InviteCodeForm = (props) => {
    const [ input, handleInputChange ] = useInputChange()
    const [ isLoading, setIsLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const { t } = useTranslation()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const codeId = input.code.trim()
            const { error, ...code } = await props.api.verifyCode(codeId)
            if (error) {
                console.error(error)
                setError(error)
                setIsLoading(false)
            }
            else {
                props.history.push("/register/user", { churchId: code.churchId, code: codeId });
            }
        }
        catch(err){
            console.error(err)
            setError(err.message)
            setIsLoading(false)
        }
    }

    return (
        <div class="card fadeInDown">

            <header class="card-header">
                {/* <img src="/logo_transparent.png" style={{ maxHeight: "3.75rem", margin: "0 40%" }} /> */}
                <h1 class="card-header-title">{t('register.code_form.title')}</h1>
            </header>

            <div class="card-content">
                <div class="content">
                    <form onSubmit={handleSubmit}>

                        <div class="field">
                            <div class="control">
                                <input name="code" placeholder="ABCXYZ..." required autofocus onChange={handleInputChange} />
                            </div>
                        </div>
                        { error && <p class="has-text-danger">{error}</p>}
                        <button type="submit" class={`button ${isLoading && 'is-loading'}`}>{t('register.code_form.cta')}</button>
                    </form>
                </div>
            </div>

        </div>
    )
} 


export default InviteCodeForm