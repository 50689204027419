import React, { useEffect, useState } from 'react';
import { Card } from 'antd';

import './AdminMetrics.scss';

const AdminMetrics = ({ api }) => {
    const [data, setData] = useState({ users: [], churches: [] });

    useEffect(() => {
        async function getData(){
            const churches = await api.getChurches();
            const users = await api.getUsers();
            setData({ churches, users });
        }

        getData();
    }, [])


    return (
        <div class="root">
            <h1 class="title is-1">Metrics</h1>
            <div class="metricsContainer">

                <Card title="Total Users">
                    <h1 class="title is-1">{data.users.length}</h1>
                </Card>

                <Card title="Total Churches">
                    <h1 class="title is-1">{data.churches.length}</h1>
                </Card>
            </div>
        </div>
    );
}

export default AdminMetrics;