import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LocaleToggle from '../../components/LocaleToggle'
import { useInputChange } from '../../hooks/index.js'
import { useTranslation } from 'react-i18next';
import './Login.scss'

const Login = (props) => {
    const [ input, handleInputChange ] = useInputChange()
    const [ isLoading, setIsLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const { t, i18n } = useTranslation();
    localStorage.removeItem('jwt')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const { jwt, error } = await props.api.login(input.email, input.password)
            if (error) {
                setError(error.message)
                setIsLoading(false)
            }
            else {
                localStorage.setItem('jwt', jwt)
                props.history.push("/church/1");
            }
        }
        catch(err){
            console.error(err)
            setError(err.message)
            setIsLoading(false)
        }
    }

    return (
        <div id="login">
            <div class="login-card fadeInDown">

                <div class="card-title">
                    <img src="/logo_transparent.png" style={{ maxHeight: "3.75rem", margin: "0 40%" }} />
                    <h1>{t('login.title')}</h1>
                </div>

                <div class="content">
                    <form onSubmit={handleSubmit}>

                        <input id="email" type="email" name="email" placeholder={t('login.email')} required autofocus onChange={handleInputChange} />
                        <input id="password" type="password" name="password" placeholder={t('login.password')} required onChange={handleInputChange} />

                        <div class="level options" >
                            {/* <div class="checkbox level-left">
                                <input type="checkbox" id="checkbox" class="regular-checkbox" />
                                <label for="checkbox"></label>
                                <span>Remember me</span>
                            </div> */}

                            <div class="level-right">
                                <Link class="btn btn-link" to="/reset-password">{t('login.forgot_password')}</Link>
                            </div>
                        </div>

                        { error && <p class="has-text-danger">{error}</p> }
                        <button type="submit" class={`button ${isLoading && 'is-loading'}`}>{t('login.cta')}</button>
                    </form>
                    <p>By clicking login you agree to our <a class="has-text-link" style={{ marginLeft: '0'}} href="/terms">Privacy Policy</a></p> 
                </div>
            </div>
            <Link class="log-in fadeInDown" to="/register">{t('login.register_link')}</Link>
            <LocaleToggle />
        </div>
    )
}

  

export default Login