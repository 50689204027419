import React, { useState, useEffect } from 'react'
import { useInputChange } from '../../hooks/index.js'

import './ResetPassword.scss'

const ResetPassword = (props) => {
    const [ input, handleInputChange ] = useInputChange()
    const [ isLoading, setIsLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [didSendLink, setDidSendLink] = useState(false)
    const [didPasswordReset, setDidPasswordReset] = useState(false)
    const [resetToken, setResetToken] = useState(null)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setResetToken(urlParams.get('token'))
    }, [])

    const handleSendLink = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const { error } = await props.api.sendPasswordReset(input.email, window.location.origin)
            if (error) {
                console.error(error)
                setError(error)
                setIsLoading(false)
            }
            else {
                setDidSendLink(true)
            }
        }
        catch(err){
            console.error(err)
            setError(err.message)
            setIsLoading(false)
        }
    }

    const handleResetPassword = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const { password, confirmPassword } = input
            if (password !== confirmPassword) throw new Error('Passwords do not match')

            const { error } = await props.api.resetPassword(resetToken, password, confirmPassword)

            if (error) {
                console.error(error)
                setError(error)
                setIsLoading(false)
            }
            else {
                setDidPasswordReset(true)
                setTimeout(() => {
                    props.history.push("/login");
                }, 3000)
            }
        }
        catch(err){
            console.error(err)
            setError(err.message)
            setIsLoading(false)
        }
    }

    return (
        <div class="bg-grey">

            {
                resetToken ? 
                    <div class="card fadeInDown">
                        <header class="card-header">
                            <h1 class="card-header-title">Enter Your New Password</h1>
                        </header>

                        <div class="card-content">
                            <div class="content">
                                {
                                    didPasswordReset ? 
                                    "Your password has been reset." : 
                                    <form onSubmit={handleResetPassword}>
                                        <div class="field">
                                            <div class="control">
                                                <input name="password" type="password" placeholder="New Password" required autofocus onChange={handleInputChange} />
                                                <input name="confirmPassword" type="password" placeholder="Confirm your new password" required autofocus onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        { error && <p class="has-text-danger">{error}</p>}
                                        <button type="submit" class={`button ${isLoading && 'is-loading'}`}>Reset Password</button>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                :
                    <div class="card fadeInDown">
                            <header class="card-header">
                                <h1 class="card-header-title">We'll send a password setup link to your account's email address. </h1>
                            </header>

                            <div class="card-content">
                                <div class="content">
                                    {
                                        didSendLink ? 
                                        'A password reset email will be sent if we find an account associated to that email.' : 
                                        <form onSubmit={handleSendLink}>
                                            <div class="field">
                                                <div class="control">
                                                    <input name="email" placeholder="Email" required autofocus onChange={handleInputChange} />
                                                </div>
                                            </div>
                                            { error && <p class="has-text-danger">{error}</p>}
                                            <button type="submit" class={`button ${isLoading && 'is-loading'}`}>Send</button>
                                        </form>
                                    }
                                </div>
                            </div>
                    </div>
            }
            

        </div>

    )
} 


export default ResetPassword