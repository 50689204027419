import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import SideDrawer from '../../components/SideDrawer'
import LocaleToggle from '../../components/LocaleToggle'
import { useTranslation } from 'react-i18next'

import './Navbar.scss'

const Navbar = ({ api, user }) => {
    const isAdmin = user && user.roles.includes('developer')
    const [showMenu, setMenu] = useState(false)
    const [allChurches, setAllChurches] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        async function getAllChurches(){
            const churches = await api.getChurches()
            setAllChurches(churches)
        }

        getAllChurches()
    }, [])

    return (
        <>
            <SideDrawer api={api} toggleDrawer={() => setMenu(!showMenu)} visible={showMenu} showAdmin={isAdmin} />
            <nav id="nav" class="navbar">
                <div class="navbar-brand">
                    <Link class="navbar-item" to="/church/1">
                        <img src="/logo.jpeg" style={{ maxHeight: "3.75rem" }} />
                        <h1 class="is-size-3">7DABromano</h1>
                    </Link>

                    <a role="button" class="navbar-burger burger" onClick={() => setMenu(!showMenu)} aria-label="menu" aria-expanded="false">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                {
                    user &&
                    <div class="navbar-menu">

                        {
                            user.churches.length > 0 &&
                                <div class="navbar-item">
                                    <div class="dropdown is-hoverable">
                                        <div class="dropdown-trigger">
                                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                            <span>{t('navbar.church_select')}</span>
                                            <span class="icon is-small">
                                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                            </button>
                                        </div>
                                        <div class="dropdown-menu" role="menu">
                                            <div class="dropdown-content">
                                                {
                                                    isAdmin ? allChurches.map( church => <Link to={{ pathname: `/church/${church.id}/feed`, state: { churchId: church.id } }} class="dropdown-item">{church.name}</Link>) 
                                                    : user.churches.map( church => <Link to={{ pathname: `/church/${church.id}/feed`, state: { churchId: church.id } }} class="dropdown-item">{church.name}</Link>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div class="navbar-item">
                            <LocaleToggle />
                        </div>

                        <div class="navbar-end">
                            <div class="navbar-item has-dropdown is-hoverable" onClick={() => setMenu(!showMenu)} style={{ marginRight: '3rem' }}>
                                <a class="navbar-link">
                                    <Avatar shape="circle" size={27} src={user.profile.avatar} icon={<UserOutlined />} />
                                </a>
                            </div>
                        </div>

                    </div>
                }

            </nav>
        </>
    )
}

export default Navbar