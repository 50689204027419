import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useInputChange } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import "react-datepicker/dist/react-datepicker.css";

const CreateEventModal = ({ isOpen, handleSave, toggle }) => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [input, handleInputChange] = useInputChange({ start: startDate, end: endDate, recurrence: 'once' })
    const [hasStartDate, setHasStartDate] = useState(false)
    const { t } = useTranslation()

    const handleStartDateChange = (date) => {
        setStartDate(date)
        setHasStartDate(true)
        input.start = startDate
    }

    const handleEndDateChange = (date) => {
        setEndDate(date)
        input.end = endDate
    }

    return (
        <div class={`modal ${isOpen && 'is-active'}`}>
            <div class="modal-background" onClick={() => toggle(!isOpen)}></div>
            <div class="modal-card fadeInDown" style={{ animationDuration: '1s' }}>
                <header class="modal-card-head">
                    <p class="modal-card-title"><i class="far fa-calendar-alt"></i>{t('calendar.modal.title')}</p>
                    <button class="delete" aria-label="close" onClick={() => toggle(!isOpen)}></button>
                </header>

                <section class="modal-card-body">
                    <fieldset disabled={false}>
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">{t('calendar.modal.start_date')}</label>
                                    <p class="control is-expanded">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            showTimeSelect
                                            timeFormat="hh:mm a"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className="input" />                                        
                                    </p>
                                </div>
                                <div class="field">
                                    <label class="label">{t('calendar.modal.end_date')}</label>
                                    <p class="control is-expanded">
                                        <DatePicker
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            disabled={!hasStartDate}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            showTimeSelect
                                            timeFormat="hh:mm a"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className="input" />   
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <label class="label">{t('calendar.modal.how_often')}</label>
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select>
                                            <option>{t('calendar.modal.once')}</option>
                                            <option>{t('calendar.modal.daily')}</option>
                                            <option>{t('calendar.modal.weekly')}</option>
                                            <option>{t('calendar.modal.monthly')}</option>
                                            <option>{t('calendar.modal.yearly')}</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">{t('calendar.modal.event_title')}</label>
                                    <div class="control">
                                        <input class="input" type="text"  placeholder="Daily Meeting" name="title" onChange={handleInputChange} />
                                    </div>
                                </div>

                                <div class="field">
                                    <label class="label">{t('calendar.modal.event_location')}</label>
                                    <div class="control">
                                        <input class="input" type="text" placeholder="My House" name="location" onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">{t('calendar.modal.event_description')}</label>

                                    <div class="control">
                                        <textarea class="textarea" placeholder="We will discuss..." name="description" onChange={handleInputChange}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </section>

                <footer class="modal-card-foot">
                    <button class="button is-success" onClick={() => handleSave(input)}>{t('calendar.modal.cta')}</button>
                    <button class="button" onClick={() => toggle(!isOpen)}>{t('calendar.modal.cancel')}</button>
                </footer>
            </div>
        </div>
    );
}

export default CreateEventModal