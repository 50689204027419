import React, { useState } from 'react'
import { useInputChange } from '../../../hooks/index.js'
import { useTranslation } from 'react-i18next'

const UserForm = (props) => {
    const [ input, handleInputChange ] = useInputChange()
    const [ isLoading, setIsLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const { t } = useTranslation()

    if (props.location.state === undefined) {
        props.history.push('/register')
        return <></>
    }
    const { churchId, code } = props.location.state

    if (!churchId || !code) props.history.push('/register')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {

            if (input.password !== input.confirmPassword){
                setError('Password do not match')
                setIsLoading(false)
            }

            const user = {
                email: input.email,
                password: input.password,
                code, 
                churchId: Number(churchId),
                firstName: input.firstName,
                lastName: input.lastName
            }
            const { error, jwt } = await props.api.register(user)
            if (error) {
                console.error(error)
                setError(typeof error === 'object' ? JSON.stringify(error) : error)
                setIsLoading(false)
            }
            else {
                localStorage.setItem('jwt', jwt)
                props.history.push("/church/1");
            }
        }
        catch(err){
            console.error(err)
            setError(err.message || 'Internal Server Error')
            setIsLoading(false)
        }
    }

    return (
        <div class="card fadeInDown">

            <header class="card-header">
                {/* <img src="/logo_transparent.png" style={{ maxHeight: "3.75rem", margin: "0 40%" }} /> */}
                <h1 class="card-header-title">{t('register.user_form.title')}</h1>
            </header>

            <div class="card-content">
                <div class="content">
                    <form onSubmit={handleSubmit}>

                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                <p class="control is-expanded">
                                    <input type="text" name="firstName" placeholder={t('register.user_form.first_name')} required autofocus onChange={handleInputChange} />
                                </p>
                                </div>
                                <div class="field">
                                <p class="control is-expanded">
                                    <input type="text" name="lastName" placeholder={t('register.user_form.last_name')} required autofocus onChange={handleInputChange} />
                                </p>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <input type="email" name="email" placeholder={t('register.user_form.email')} required autofocus onChange={handleInputChange} />
                                <input type="password" name="password" placeholder={t('register.user_form.password')} required autofocus onChange={handleInputChange} />
                                <input type="password" name="confirmPassword" placeholder={t('register.user_form.confirm_password')} required autofocus onChange={handleInputChange} />
                            </div>
                        </div>

                        <p>By clicking register you agree to our <a class="has-text-link" style={{ marginLeft: '0'}} href="/terms">Privacy Policy</a></p>
                        { error && <p class="has-text-danger">{error}</p>}
                        <button type="submit" class={`button ${isLoading && 'is-loading'}`}>{t('register.user_form.cta')}</button>
                    </form>
                </div>
            </div>

        </div>    
    )
} 

export default UserForm