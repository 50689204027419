import React from 'react'
import { useTranslation } from 'react-i18next'

const LocaleToggle = () => {
    const { t, i18n } = useTranslation();

    function changeLocale(localeCode) {
        i18n.changeLanguage(localeCode)
    }

    return (
        <div class="dropdown is-hoverable">
            <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>{t('locale_toggle.cta')}</span>
                    <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <a class="dropdown-item" onClick={() => changeLocale('en')}>{t('locale_toggle.english')}</a>
                    <a class="dropdown-item" onClick={() => changeLocale('es')}>{t('locale_toggle.spanish')}</a>
                </div>
            </div>
        </div>
    )
}

export default LocaleToggle