import React from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import './ChurchNav.scss'

const ChurchNav = ({ church }) => {
    const { t } = useTranslation()

    return (
        <nav id="church-nav" class="level is-mobile fadeIn">
            <p class="level-item has-text-centered">
                <Link class="link is-info" to={`/church/${church.id}/feed`}>{t('home.nav.posts')}</Link>
            </p>
            <p class="level-item has-text-centered">
                <Link class="link is-info" to={`/church/${church.id}/calendar`}>{t('home.nav.calendar')}</Link>
            </p>
            <p class="level-item has-text-centered">
                <Link class="link is-info" to={`/church/${church.id}/directory`}>{t('home.nav.directory')}</Link>
            </p>
        </nav>
    )
}

export default ChurchNav