class AppsoluteAPI {
    constructor(jwt, history){
        this.baseURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://api.johncenatwitter.com'
        // this.baseURL = 'https://api-proxy-5k4jhnouba-uc.a.run.app' //dev
        // this.baseURL = 'https://api-proxy-bdccsvsgpa-uc.a.run.app'; //prod
        this.options = {
            method: '',
            headers: {
                'Content-Type': 'application/json',
                Authorization: jwt ? `Bearer ${jwt}`: ''
            }
        }
        this.history = history
    }

    async fetch(url, options){
        const result = await fetch(url, options)
        if (result.status == 401) this.history.push('/login')
        else return await result.json()
    }

    async login(email, password){
        this.options.method = 'POST'
        this.options.body = JSON.stringify({ email, password })
        return await this.fetch(`${this.baseURL}/auth/login`, this.options)
    }

    async register(user){
        this.options.method = 'POST'
        this.options.body = JSON.stringify(user)
        return await this.fetch(`${this.baseURL}/auth/register`, this.options)
    }

    async me(){
        this.options.method = 'GET'
        delete this.options.body
        return await this.fetch(`${this.baseURL}/auth/me`, this.options)
    }

    async verifyCode(code){
        this.options.method = 'POST'
        this.options.body = JSON.stringify({ codeId: code })
        return await this.fetch(`${this.baseURL}/code/verify`, this.options)
    }

    async activateCode(code){
        this.options.method = 'POST'
        this.options.body = JSON.stringify({ codeId: code })
        return await this.fetch(`${this.baseURL}/code/activate`, this.options)
    }

    async makeCodes(churchId, count){
        this.options.method = 'POST'
        this.options.body = JSON.stringify({ churchId, count })
        return await this.fetch(`${this.baseURL}/code/create`, this.options)
    }

    async getChurchPosts(churchId){
        this.options.method = 'GET'
        delete this.options.body
        return await this.fetch(`${this.baseURL}/post?churchId=${churchId}`, this.options)
    }

    async createPost(post){
        delete this.options.headers["Content-Type"] //multer middleware throws an error if content-type header exists
        this.options.method = 'POST'
        let data = new FormData();
        Object.keys(post).forEach( key => {
            if (key === 'images') {
                post[key].forEach( file => {
                    data.append(key, file, file.name);
                })
            } else {
                data.append(key, post[key]);
            }
        })
        this.options.body = data;
        const results = await this.fetch(`${this.baseURL}/post`, this.options);
        this.options.headers["Content-Type"] = "application/json";
        return results;
    }

    async updatePost(postId, post){
        delete this.options.headers["Content-Type"] //multer middleware throws an error if content-type header exists
        this.options.method = 'PUT'
        let data = new FormData();

        Object.keys(post).forEach( key => {
            if (key === 'images') {
                post[key].forEach( file => {
                    if (typeof file === 'string') {
                        data.append(key, file)
                    }
                    else if (typeof file === 'object') {
                        data.append(key, file, file.name);
                    }
                })
            } else {
                data.append(key, post[key]);
            }
        })
        
        this.options.body = data;
        const results = await this.fetch(`${this.baseURL}/post/${postId}`, this.options);
        this.options.headers["Content-Type"] = "application/json";
        return results;
    }

    async deletePost(postId){
        this.options.method = 'DELETE'
        delete this.options.body;
        let url = `${this.baseURL}/post/${postId}`
        return await this.fetch(url, this.options)
    }

    async getUsers(churchId){
        this.options.method = 'GET'
        delete this.options.body
        let url = `${this.baseURL}/user`
        if (churchId) url += `?churchId=${churchId}`
        return await this.fetch(url, this.options)
    }

    async getUser(userId){
        this.options.method = 'GET'
        delete this.options.body
        let url = `${this.baseURL}/user/${userId}`
        return await this.fetch(url, this.options)
    }

    async updateUser(userId, updateFields){
        delete this.options.headers["Content-Type"] //multer middleware throws an error if content-type header exists
        const url = `${this.baseURL}/user/${userId}`
        this.options.method = "PUT";
        let data = new FormData();
        Object.keys(updateFields).forEach( key => {
            if (Array.isArray(updateFields[key])){
                updateFields[key].forEach( (i, index) => data.append(`${key}[${index}]`, i))
            }
            else data.append(key, updateFields[key])
        })
		this.options.body = data;
        const results = await this.fetch(url, this.options);
        this.options.headers["Content-Type"] = "application/json";
        return results
    }

    async createChurch(churchFields){
        this.options.method = 'POST'
        this.options.body = JSON.stringify(churchFields)
        let url = `${this.baseURL}/church`
        return await this.fetch(url, this.options)
    }

    async updateChurch(churchId, updateFields){
        delete this.options.headers["Content-Type"] //multer middleware throws an error if content-type header exists
        console.log(Array.isArray(updateFields.churchAdmins))
        const url = `${this.baseURL}/church/${churchId}`
        this.options.method = "PUT";
        let data = new FormData();
        Object.keys(updateFields).forEach( key => {
            if (Array.isArray(updateFields[key])){
                updateFields[key].forEach( (i, index) => data.append(`${key}[${index}]`, i))
            }
            else data.append(key, updateFields[key])
        })
		this.options.body = data;
        const results = await this.fetch(url, this.options);
        this.options.headers["Content-Type"] = "application/json";
        return results
    }

    async getCalendarEvents(churchId){
        this.options.method = 'GET'
        delete this.options.body
        let url = `${this.baseURL}/calendar`
        if (churchId) url += `?churchId=${churchId}`
        return await this.fetch(url, this.options)
    }

    async createCalendarEvent(eventFields){
        this.options.method = 'POST'
        this.options.body = JSON.stringify(eventFields)
        let url = `${this.baseURL}/calendar`
        return await this.fetch(url, this.options)
    }

    async updateCalendarEvent(eventId, eventFields){
        this.options.method = 'PUT'
        this.options.body = JSON.stringify(eventFields)
        let url = `${this.baseURL}/calendar/${eventId}`
        return await this.fetch(url, this.options)
    }

    async deleteCalendarEvent(eventId){
        this.options.method = 'DELETE'
        delete this.options.body;
        let url = `${this.baseURL}/calendar/${eventId}`
        return await this.fetch(url, this.options)
    }

    async getChurches(showDeleted = false){
        this.options.method = 'GET'
        delete this.options.body
        let url = `${this.baseURL}/church`
        if (showDeleted) url += `?showDeleted=true`
        return await this.fetch(url, this.options)
    }

    async getChurch(churchId){
        this.options.method = 'GET'
        delete this.options.body
        let url = `${this.baseURL}/church/${churchId}`
        return await this.fetch(url, this.options)
    }

    async sendPasswordReset(email, hostname){
        this.options.method = 'POST'
        this.options.body = JSON.stringify({ email, hostname })
        let url = `${this.baseURL}/auth/send-reset-password`
        return await this.fetch(url, this.options)
    }

    async resetPassword(token, password, confirmPassword){
        this.options.method = 'POST'
        this.options.body = JSON.stringify({ token, password, confirmPassword })
        let url = `${this.baseURL}/auth/reset-password`
        return await this.fetch(url, this.options)
    }

    async removeChurchUser(churchId, userId){
        this.options.method = 'PUT'
        this.options.body = JSON.stringify({ userId })
        let url = `${this.baseURL}/church/${churchId}/removeUser`
        return await this.fetch(url, this.options)
    }

    async deleteAccount(userId){
        this.options.method = 'DELETE'
        delete this.options.body
        let url = `${this.baseURL}/user/${userId}`
        return await this.fetch(url, this.options)
    }
}

export default AppsoluteAPI