import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { toast } from 'react-toastify'

import './AdminChurches.scss'

const AdminChurches = ({ api }) => {
    const [churches, setChurches] = useState([])

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Last Modified',
          dataIndex: 'lastModifiedAt',
          key: 'lastModifiedAt',
        },
        {
          title: 'Action',
          key: 'id',
          dataIndex: 'id',
          render: id => {
            const church = churches.find( church => church.id === id )
            return (
                <>
                    <Link class="button is-info" to={`/church/${id}/feed`}><i class="fas fa-eye"></i>View</Link>
                    <Link class="button is-success" to={`/church/${id}/admin`}><i class="fas fa-edit"></i>Edit</Link>
                    { church.deleted ? <button class="button is-success" onClick={() => { changeChurchStatus(id, false) }}><i class="fas fa-check-circle"></i>Reactivate</button> : 
                                       <button class="button is-danger" onClick={() => { changeChurchStatus(id, true) }}><i class="fas fa-trash"></i>Delete</button> }
                </>
                )
            },
        },
    ];

    async function getChurches(){
        const showDeleted = true
        const churches = await api.getChurches(showDeleted);
        setChurches(churches)
    }

    async function changeChurchStatus(churchId, shouldDelete) {
        const confirmed = window.confirm(`Are you sure you want to ${shouldDelete ? 'deactivate' : 'reactivate'} this church?`)
        if (confirmed){
            const { error, ...result } = await api.updateChurch(churchId, { deleted: shouldDelete })
            if (error){
                toast.error(`Error ${shouldDelete ? 'deactivating' : 'reactivating'} church ${error}`)
            }
            else {
                await getChurches()
            }
        }
    }

    useEffect(() => {
        getChurches()
    }, [])

    async function createChurch(){
        const name = window.prompt("Please choose a new name for the new church")
        
        try {
            if (name){
                const { error, ...result } = await api.createChurch({ name })
                if (error){
                    toast.error(error)
                }
                else {
                    await getChurches()
                }
            }
        }
        catch(error){
            toast.error(error)
        }
    }

    return (
        <section class="churchesContainer">
            <h1 class="title is-1">Churches</h1>
            <a class="button is-info" onClick={createChurch}><i class="fas fa-plus"/>New Church</a>
            <Table columns={columns} dataSource={churches} />
        </section>
    )
}

export default AdminChurches;