import { useState } from 'react'

export const useInputChange = (state = {}) => {
    const [ input, setInput ] = useState(state)
    const handleInputChange = (e) => setInput({
        ...input,
        [e.currentTarget.name]: e.currentTarget.value
    })

    return [input, handleInputChange]
}