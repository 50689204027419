import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import {
    Calendar,
    momentLocalizer,
} from 'react-big-calendar'
import moment from 'moment'
import { RRule, RRuleSet, rrulestr } from 'rrule'
import CreateEventModal from './components/CreateEventModal'
import ViewEventModal from './components/ViewEventModal'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './ChurchCalendar.scss'

import 'moment/locale/es';

const ChurchCalendar = ({ api, user, church }) => {
    const [events, setEvents] = useState([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showViewModal, setShowViewModal] = useState(false)
    const [currentEvent, setCurrentEvent] = useState({})
    const { t, i18n } = useTranslation()

    function makeRecurringEvent(event, ruleFreq){
        const recurringEvents = []
        const startDates = new RRule({
            freq: ruleFreq,
            interval: 1,
            dtstart: new Date(event.start),
            until: new Date(new Date(event.start).setFullYear(new Date().getFullYear() + 2)) //sets until two years from now
        });

        const endDates = new RRule({
            freq: ruleFreq,
            interval: 1,
            dtstart: new Date(event.end),
            until: new Date(new Date(event.end).setFullYear(new Date().getFullYear() + 2)) //sets until two years from now
        });

        startDates.all()
        endDates.all()

        for (let i = 0; i < startDates._cache.all.length; i++){
            recurringEvents.push({
                ...event,
                start: startDates._cache.all[i],
                end: endDates._cache.all[i],
            })
        }
        
        return recurringEvents
    }

    function makeRecurringEvents(events){
        let recurringEvents = []
        for (let event of events){
            switch(event.recurrence){
                case 'once':
                    event.start = new Date(event.start)
                    event.end = new Date(event.end)
                    recurringEvents.push(event)
                    break;
                case 'daily':
                    recurringEvents = recurringEvents.concat(makeRecurringEvent(event, RRule.DAILY))
                    break;
                case 'weekly':
                    recurringEvents = recurringEvents.concat(makeRecurringEvent(event, RRule.WEEKLY))
                    break;
                case 'monthly':
                    recurringEvents = recurringEvents.concat(makeRecurringEvent(event, RRule.MONTHLY))
                    break;
                case 'yearly':
                    recurringEvents = recurringEvents.concat(makeRecurringEvent(event, RRule.YEARLY))
                    break;
            }
        }

        return recurringEvents
    }

    async function getEvents(){
        const events = await api.getCalendarEvents(church.id)
        setEvents(makeRecurringEvents(events))
    }

    useEffect(() => {
        getEvents()
    }, [church, user])

    const saveEvent = async (event) => {
        console.log(event)
        const eventFields = {
            churchId: Number(church.id),
            ...event
        }
        const { error, ...result } = await api.createCalendarEvent(eventFields)
        if (error){
            console.error(error)
            toast.error(error)
        }
        else {
            await getEvents()
            setShowCreateModal(false)
        }
    }

    const updateEvent = async (eventId, eventFields) => {
        const { error, ...result } = await api.updateCalendarEvent(eventId, eventFields)
        if (error){
            console.error(error)
            toast.error(error)
        }
        else {
            await getEvents()
            setShowViewModal(false)
        }
    }

    const deleteEvent = async (eventId) => {
        const { error, ...result } = await api.deleteCalendarEvent(eventId)
        if (error){
            console.error(error)
            toast.error(error)
        }
        else {
            await getEvents()
            setShowViewModal(false)
        }
    }

    const canEditCalendar = church.churchAdmins.includes(user.user_id) || user.roles.includes('developer')

    moment.locale(i18n.language, { week: {
        dow: 0, // First day of week is Sunday
        doy: 6  // First week of year must contain 1 January (7 + 0 - 1)
    }});

    const localizer = momentLocalizer(moment) // or globalizeLocalizer
    const formats = {
        weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
    }
    const messages = {
        date: t('calendar.date'),
        time: t('calendar.time'),
        event: t('calendar.event'),
        allDay: t('calendar.all_day'),
        week: t('calendar.week'),
        work_week: 'string',
        day: t('calendar.day'),
        month: t('calendar.month'),
        previous: t('calendar.previous'),
        next: t('calendar.next'),
        yesterday: t('calendar.yesterday'),
        tomorrow: t('calendar.tomorrow'),
        today: t('calendar.today'),
        agenda: t('calendar.agenda'),
        showMore: (count) => 'Show more',
        noEventsInRange: 'string',
    }
    return (
        <div class="calendar-container">
            <CreateEventModal handleSave={saveEvent} isOpen={showCreateModal} toggle={setShowCreateModal} />
            <ViewEventModal updateEvent={updateEvent} deleteEvent={deleteEvent} canEdit={canEditCalendar} isOpen={showViewModal} toggle={setShowViewModal} event={currentEvent} />

            <div class="level">
                <div class="level-left">
                </div>
                <div class="level-right">
                    { canEditCalendar && <a class="button" onClick={() => setShowCreateModal(true)}><i class="fas fa-plus"></i>{t('calendar.create_event')}</a> }
                </div>
            </div>
            <div class="church-calendar">
                <Calendar
                    popup
                    formats={formats}
                    messages={messages}
                    selectable={canEditCalendar}  
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={event => { 
                        setCurrentEvent(event);
                        setShowViewModal(true)
                    }}
                    dayLayoutAlgorithm={'no-overlap'}
                    style={{ height: 500 }}
                />
            </div>
        </div>
    )
}

export default ChurchCalendar