import React, { useState } from 'react'
import {useDropzone} from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import './FileDrop.scss'

const MAX_IMAGES = 4

const FileDrop = ({ handleDrop, existingImages = 0, files }) => {
    const { t } = useTranslation()
    const onDrop = (acceptedFiles) => {
        if ((existingImages + files.length) < MAX_IMAGES){
            const updatedFiles = files.concat(acceptedFiles)
            handleDrop(updatedFiles)
        }
    }
    const maxFileSize = 20000000
    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxSize: maxFileSize, multiple: true, accept: 'image/*' });
    
    const removeFile = (removedFile) => {
        const updatedFiles = files.filter( file => file.path !== removedFile.path )
        handleDrop(updatedFiles)
    }

    const renderFilePreviews = (files) => {
        return files.map(file => (
            <li key={file.path} onClick={() => removeFile(file)}>
                {file.path} - {file.size / 1000000} megabytes
            </li>
        ))
    }
    


    return (
        <section className="fileDropContainer">
            <label class="label">{t('file_drop.title')}</label>
            <p>{ `${existingImages + files.length}/${MAX_IMAGES}` }</p>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>{t('file_drop.images_cta')}</p>
            </div>
            <aside>
                <ul>{renderFilePreviews(files)}</ul>
            </aside>
        </section>
    );
}

export default FileDrop;