import React from 'react'
import { useTranslation } from 'react-i18next'
import './ChurchInfo.scss'


const ChurchInfo = ({ church }) => {
    const { t } = useTranslation()
    return (
        <div class="card fadeInBottom churchInfo">
            <div class="card-border"></div>

            <div class="card-content">
                <h4>{t('home.info.description')}</h4>
                <p>{church.description}</p>

                <h4>{t('home.info.address')}</h4>
                <p>{church.address}</p>

                <div class="field is-grouped">
                    { 
                        church.twitter && 
                            <p class="control">
                                <a href={church.twitter} target="_blank">
                                    <button class="button">
                                        <span class="icon">
                                            <i class="fab fa-twitter"></i>
                                        </span>
                                    </button>
                                </a>
                            </p> 
                    }
                    { 
                        church.instagram && 
                            <p class="control">
                                <a href={church.instagram} target="_blank">
                                    <button class="button">
                                        <span class="icon">
                                            <i class="fab fa-instagram"></i>
                                        </span>
                                    </button>
                                </a>
                            </p> 
                    }
                    { 
                        church.facebook && 
                            <p class="control">
                                <a href={church.facebook} target="_blank">
                                    <button class="button">
                                        <span class="icon">
                                            <i class="fab fa-facebook"></i>
                                        </span>
                                    </button>
                                </a>
                            </p> 
                    }
                </div>
            </div>
        </div>
    );
}

export default ChurchInfo