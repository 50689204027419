import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatPhoneNumber } from 'react-phone-number-input'
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Loader from '../../components/Loader'
import { toast } from 'react-toastify'

import './UserDirectory.scss'

const UserCard = ({ user, showDelete, removeUser }) => {
    const name = `${user.firstName} ${user.lastName}`
    const email = `${user.email}`
    const phone = user.phoneNumber ? `${formatPhoneNumber(user.phoneNumber)}` : 'n/a'
    const { t } = useTranslation()

    const avatarSize = () => {
        const windowWidth = window.screen.width
        if (windowWidth < 768){ //mobile
            return 60
        }
        if (windowWidth >= 768 && windowWidth < 1024){ //tablet
            return 80
        }
        else return 100
    }

    return (
        <div class="user-card">
            <div class="avatar">
                <Avatar shape="circle" size={avatarSize()} src={user.avatar} icon={<UserOutlined />} />
            </div>
            <div class="info">
                <Link class="name" to={`/user/${user.id}`}>{name}</Link>
                <p class="email"><i class="fa fa-envelope"></i>{email}</p>
                <p class="phone"><i class="fa fa-phone-alt"></i>{phone}</p>
            </div>
            {
                showDelete && 
                <div class="remove">
                    <button class="button has-tooltip-top" data-tooltip={t('directory.remove_user')} onClick={() => { removeUser(user.id) }}>
                        <span class="icon has-text-danger">
                            <i class="fas fa-ban"></i>
                        </span>
                    </button>
                </div>
            }

        </div>
    )
}


const UserDirectory = ({ api, church, isAdmin }) => {
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function getUsers(){
            const users = await api.getUsers(church.id)
            setUsers(users.sort((a, b) => a[sortKey] > b[sortKey] ? 1 : 0  ))
            setIsLoading(false)
        }
        
        getUsers()
    }, [church])

    async function removeUser(userId){
        const confirmed = window.confirm('Are you sure you want to remove this user from the church')
        if (confirmed){
            const { error, ...result } = await api.removeChurchUser(church.id, userId)
            if (error) {
                toast.error(error)
            }
            else {
                setUsers(users.filter( user => user.id !== userId ))
            }
        }
    }

    const sortKey = 'lastName'
    return (
        <section class="directory-grid">
            { isLoading ? <Loader /> : users.map( u => <UserCard user={u} showDelete={isAdmin} removeUser={removeUser} /> ) }
        </section>
    )
}

export default UserDirectory