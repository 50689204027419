import React from 'react'
import AppsoluteAPI from '../api/index.js'

const withAPI = (WrappedComponent) => {

    return class extends React.Component {

        constructor(props){
            super(props)
            this.state = {}
            this.state.api = new AppsoluteAPI(localStorage.getItem('jwt'), props.history)
        }

        render(){
            return <WrappedComponent api={this.state.api} {...this.props} />
        }
    }

}

export default withAPI